<template>
  <main v-if="props.show" class="flex p-20 items-center justify-center">
    <div class="border-gray mr-3 h-48 w-48 animate-spin rounded-full border-4 border-t-4 border-t-red-500" />
  </main>
</template>

<script setup lang="ts">
const props = defineProps<{
  show: boolean;
}>();
</script>
